import Layout from '../containers/layout.js';
import React, {useEffect} from 'react';
import { graphql } from 'gatsby';

const Page = ({ data, location }) => {
  const seoData = data.strapiPages.SEO;
  const content = data.strapiPages.CustomHTML;

  useEffect(() => {

      // Use DOM manipulation to search content for JavaScript and execute it
      const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
      const scriptTags = content.HTML.match(scriptRegex);

      if (scriptTags) {
        const sanitizedContent = content.HTML.replace(scriptRegex, ''); // Remove script tags
        scriptTags.forEach((tag) => {
          const scriptContent = tag.replace(/<\/?script>/g, ''); // Extract JavaScript code
          try {
            // Use Function constructor to execute the JavaScript code
            const scriptFunction = new Function(scriptContent);
            scriptFunction();
          } catch (error) {
            console.error('Error executing JavaScript:', error);
          }
        });
      }




  }, []);

  return (
    <>
      <Layout seoData={seoData} location={location}>
        <div
          style={{ display: 'none' }}
          dangerouslySetInnerHTML={{ __html: content.Style && content.Style }}
        />
        <section
          dangerouslySetInnerHTML={{ __html: content.HTML && content.HTML }}
        />
      </Layout>
    </>
  );
};

export const query = graphql`
  query customPageQuery($id: String!) {
    strapiPages(id: { eq: $id }) {
      id
      Title
      HasCustomHTML
      CustomHTML {
        HTML
        Style
      }
      SEO {
        MetaTitle
        MetaDescription
        Keywords
        Slug
        PreviewImage {
          url
          width
          height
          formats {
            small {
              url
              width
              name
              height
            }
          }
        }
      }
    }
  }
`;

export default Page;